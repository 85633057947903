<template>
  <div id="events-preview">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ pageTitle }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>

        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="submitForm"
        >
          <v-container v-if="ResultFlag">
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Submitted Date</h5>
                <p class="preview-content">{{ row.CreatedAtTxt }}</p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Reference No</h5>
                <p class="preview-content">{{ row.ReferenceNo }}</p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Department Name</h5>
                <p class="preview-content">{{ row.DepartmentName }}</p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Type Name</h5>
                <p class="preview-content">{{ row.TypeName }}</p>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Subject</h5>
                <p class="preview-content">{{ row.Subject }}</p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Contnet</h5>
                <p class="preview-content">{{ row.Contnet }}</p>
              </v-col>

              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Attachments</h5>
                <span v-if="row.AttachmentImage !== ''">
                  <!-- <v-btn color="primary" dark @click="openImage2">
                    View Attachment
                  </v-btn> -->
                  <viewer rebuild :images="row.AllImages">
                    <img
                      style="padding: 5px"
                      width="100"
                      height="100"
                      :src="src"
                      :key="src"
                      v-for="src in row.AllImages"
                    />
                  </viewer>
                </span>
              </v-col>
            </v-row>
            <hr />
            <br />
            <v-row wrap v-if="row.ActiveStatus">
              <v-col cols="12">
                <h6>Write Message</h6>
                <v-textarea
                  v-if="row.ActiveStatus"
                  v-model="Content"
                  placeholder="Message here"
                  required
                  outlined
                  solo
                  :rules="ContentRules"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" lg="5" md="5">
                <h6 class="mycard-text1 fsize-4">
                  Upload Attachment
                  <p class="notes-text1">
                    (only jpg, png image files are accepted)
                  </p>
                </h6>
                <v-file-input
                  :clearable="false"
                  truncate-length="25"
                  accept="image/png, image/jpeg"
                  placeholder="Upload Proof Image"
                  prepend-icon="mdi-card-bulleted-settings"
                  append-outer-icon="mdi-delete"
                  v-model="UploadedImage1"
                  @change="addImage(1)"
                >
                  <template v-slot:append-outer>
                    <v-icon color="red" @click="deleteImage(1)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-file-input>
              </v-col>
              <v-col align="center" cols="12" sm="6" lg="7" md="7">
                <h6 class="mycard-text1 fsize-4">
                  Uploaded Image will Appear Here
                </h6>
                <br />
                <cropper ref="cropper" :src="SelectedImage1.ImageUrl" />
              </v-col>
            </v-row>
            <v-row wrap v-if="row.ActiveStatus">
              <v-col align="center" cols="12" md="12">
                <v-btn
                  @click.prevent="uploadImage(1)"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  large
                  class="font-size-h6 mr-3 my-3 white--text"
                >
                  &nbsp; Reply &nbsp;
                </v-btn>

                <v-btn
                  @click.prevent="uploadImage(0)"
                  color="blue"
                  elevation="30"
                  shaped
                  large
                  class="font-size-h6 mr-3 my-3 white--text"
                >
                  &nbsp; Reply&Close &nbsp;
                </v-btn>

                <v-btn
                  @click.prevent="closePrompt"
                  color="brown"
                  elevation="30"
                  shaped
                  large
                  class="font-size-h6 mr-3 my-3 white--text"
                >
                  &nbsp; Exit &nbsp;
                </v-btn>
              </v-col>
            </v-row>
            <hr v-if="row.ActiveStatus" />
            <br />
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12" align="center">
                <h4>Message Details</h4>
              </v-col>
            </v-row>
            <v-row wrap v-for="row in row.ResponseArray" :key="row.ResponseId">
              <v-col
                cols="6"
                offset="6"
                style="color: green; font-size: 20px"
                v-if="row.Sender == 1"
              >
                {{ row.ResponseByNameTxt }} - {{ row.ResponseCreatedAT }}
                <br /><br />
                {{ row.ResponseMessageTxt }}

                <span v-if="row.ResponseAttachmentImage !== ''">
                  <!-- <v-btn color="primary" dark @click="openImage">
                    View Attachment
                  </v-btn> -->
                  <viewer
                    rebuild
                    :images="row.ResponseAllImages"
                  >
                    <img
                      style="padding: 5px"
                      width="100"
                      height="100"
                      :src="src"
                      :key="src"
                      v-for="src in row.ResponseAllImages"
                    />
                  </viewer>
                </span>
              </v-col>
              <v-col v-else cols="6" style="color: brown; font-size: 20px">
                {{ row.ResponseByNameTxt }} - {{ row.ResponseCreatedAT }}
                <br /><br />
                {{ row.ResponseMessageTxt }}
                <span v-if="row.ResponseAttachmentImage !== ''">
                  <!-- <v-btn color="primary" dark @click="openImage1">
                    View Attachment
                  </v-btn> -->
                  <viewer
                    rebuild
                    :images="row.ResponseAllImages"
                  >
                    <img
                      style="padding: 5px"
                      width="100"
                      height="100"
                      :src="src"
                      :key="src"
                      v-for="src in row.ResponseAllImages"
                    />
                  </viewer>
                </span>
              </v-col>
            </v-row>
            <br />
          </v-container>
        </v-form>
      </v-card-text>
      <!-- <v-card-actions v-if="previewRecordFlag">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt"> Cancel </v-btn>
      </v-card-actions> -->
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    previewRecordFlag: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      Width: 500,

      valid1: false,
      valid2: false,
      ProgressLoadingFlag: false,
      ResultFlag: false,
      LoadingFlag: false,
      row: {},
      PaymentDetails: {},
      selected: [],

      // ImageFlag: false,
      // ImageFlag1: false,
      // ImageFlag2: false,

      Content: "",
      ContentRules: [(v) => !!v || "Message is required"],
      UploadedImage1: {},
      SelectedImage1: { src: null },

      dialog: false,
      dialog1: false,

      tableColumns: [],
      tableOptions: [],
      tableData: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var recordData = this.recordData;
      console.log("recordData=" + JSON.stringify(recordData));
      this.getTableRecords();
    },
    // openImage() {
    //   console.log("openImage called");
    //   this.ImageFlag = true;
    // },
    // openImage1() {
    //   console.log("openImage1 called");
    //   this.ImageFlag1 = true;
    // },
    // openImage2() {
    //   console.log("openImage1 called");
    //   this.ImageFlag2 = true;
    // },
    getTableRecords() {
      console.log("getTableRecords called");

      var ReferenceNo = this.recordData.ReferenceNo;
      var FromUserId = this.recordData.FromUserId;
      var DepartmentId = this.recordData.DepartmentId;
      var TypeId = this.recordData.TypeId;
      var CreatedAtTxt = this.recordData.CreatedAtTxt;
      var UpdatedAtTxt = this.recordData.UpdatedAtTxt;
      var UserInterface = 1;
      console.log(
        ", ReferenceNo=" +
          ReferenceNo +
          ", FromUserId=" +
          FromUserId +
          ", DepartmentId=" +
          DepartmentId +
          ", TypeId=" +
          TypeId +
          ", CreatedAtTxt=" +
          CreatedAtTxt +
          ", UpdatedAtTxt=" +
          UpdatedAtTxt
      );

      if (UserInterface != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/ticket/show";
        var upload = {
          UserInterface: UserInterface,
          FromUserId: FromUserId,
          ReferenceNo: ReferenceNo,
          DepartmentId: DepartmentId,
          TypeId: TypeId,
          CreatedAtTxt: CreatedAtTxt,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.row = {};
        this.ResultFlag = false;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.LoadingFlag = true;
        this.Documentpath = "";

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.ResultFlag = flag;

            if (flag == 1) {
              thisIns.row = records;
              console.log("records=" + JSON.stringify(records));
              // thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Your session is expired. Kindly login again. ";
        console.log("error=" + message);
      }
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.UploadedImage1;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.SelectedImage1.src) {
            URL.revokeObjectURL(this.SelectedImage1.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.SelectedImage1 = {
            ImageName: file.name,
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.SelectedImage1 = {
            ImageName: null,
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null,
          };
          this.UploadedImage1 = {};
          break;

        default:
          break;
      }
    },
    uploadImage(status) {
      console.log("uploadImage called");
      var Status = status;
      console.log("Status = " + Status);
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        const formData = new FormData();
        formData.append("TicketId", this.row.TicketId);
        formData.append("Content", this.Content);
        formData.append("Status", Status);
        var UploadedImageName = this.UploadedImage1.name;
        UploadedImageName =
          UploadedImageName == (null || undefined) ? "" : UploadedImageName;
        if (UploadedImageName != "") {
          const { canvas } = this.$refs.cropper.getResult();
          if (canvas) {
            canvas.toBlob((blob) => {
              formData.append("UploadedFile1", blob, UploadedImageName);
              this.UploadFlag = true;
              this.submitForm(formData);
            }, "image/jpeg");
          } else {
            this.submitForm(formData);
          }
        } else {
          this.submitForm(formData);
        }
      } else {
        var message = "";
        if (validate1 == false) {
          message += "Kindly fill the required fields. ";
        }
        if (MemberId == 0) {
          message += "Kindly select member. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm(upload) {
      console.log("submitForm is called");
      this.ProgressLoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/ticket-response/store";

      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var table = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.ProgressLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.refreshPageData();
            thisIns.Content = "";
            thisIns.deleteImage(1);
            // thisIns.$emit("hideEditRecordPrompt");
          } else {
            thisIns.sweetAlert("error", output, false);
            //   thisIns.getTableRecords();
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SearchFlag = false;
          thisIns.ProgressLoadingFlag = true;
        });
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#events-preview {
  .preview-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5em;
  }
}
</style>