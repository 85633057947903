<template>
  <div id="module-create">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="submitForm"
          >
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required field
                  </p>
                </v-col>
                <v-col cols="12" sm="12" lg="6" md="6">
                  <label
                    ><h6>
                      <span class="text-danger">*</span>Select Department
                    </h6></label
                  >
                  <v-autocomplete
                    @change="getTypeDetails"
                    :reduce="(option) => option.value"
                    :loading="DepartmentIdOptionsLoading"
                    :items="DepartmentIdOptions"
                    :rules="DepartmentIdRules"
                    v-model="DepartmentId"
                    required
                    outlined
                    dense
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" lg="6" md="6">
                  <label
                    ><h6>
                      <span class="text-danger">*</span>Select Type
                    </h6></label
                  >
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="TypeIdOptionsLoading"
                    :items="TypeIdOptions"
                    :rules="TypeIdRules"
                    v-model="TypeId"
                    required
                    outlined
                    dense
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" lg="12" md="12">
                  <label>
                    <h6><span class="text-danger">*</span> Subject</h6>
                  </label>
                  <v-text-field
                    v-model="Subject"
                    placeholder="Subject"
                    :rules="SubjectRules"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" lg="12" md="12">
                  <h6><span class="text-danger">*</span> Content</h6>
                  <v-textarea
                    v-model="Content"
                    placeholder="Content"
                    :rules="ContentRules"
                    required
                    outlined
                    solo
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" lg="4" md="4">
                  <h6 class="mycard-text1 fsize-4">
                    Upload Screenshot
                    <p class="notes-text1">
                      (only jpg, png image files are accepted)
                    </p>
                  </h6>
                  <v-file-input
                    :clearable="false"
                    truncate-length="25"
                    accept="image/png, image/jpeg"
                    placeholder="Upload Proof Image"
                    prepend-icon="mdi-card-bulleted-settings"
                    append-outer-icon="mdi-delete"
                    v-model="UploadedImage1"
                    @change="addImage(1)"
                  >
                    <template v-slot:append-outer>
                      <v-icon color="red" @click="deleteImage(1)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col align="center" cols="12" sm="6" lg="4" md="4">
                  <h6 class="mycard-text1 fsize-4">
                    Uploaded screenshot will Appear Here
                  </h6>
                  <br />
                  <cropper ref="cropper" :src="SelectedImage1.ImageUrl" />
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="confirmAlert"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  mixins: [common],
  components: {
    Cropper,
    CircleStencil,
  },
  props: {
    // AddRecordPrompt: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,

      UploadFlag: false,
      ResultFlag: false,

      DepartmentIdRules: [(v) => !!v || "Department is required"],
      DepartmentId: "",
      DepartmentIdOptions: [],
      DepartmentIdOptionsLoading: false,

      TypeIdRules: [(v) => !!v || "Type is required"],
      TypeId: "",
      TypeIdOptions: [],
      TypeIdOptionsLoading: false,

      SubjectRules: [(v) => !!v || "Subject is required"],
      ContentRules: [(v) => !!v || "Content is required"],

      Subject: "",
      Content: "",

      image: {
        // src: "media/images/jci-logo.png",
        src: "",
        type: null,
      },

      UploadedImage1: {},
      SelectedImage1: { src: null },

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.getDepartmentDetails();
      }
    },

    DepartmentIdOptions: function () {
      this.DepartmentIdOptionsLoading = false;
    },

    TypeIdOptions: function () {
      this.TypeIdOptionsLoading = false;
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "support_tickets",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    getDepartmentDetails() {
      console.log("getDepartmentDetails called");
      this.DepartmentIdOptionsLoading = true;
      var selectbox1_source = "DepartmentId";
      var selectbox1_destination = "DepartmentIdOptions";
      var selectbox1_url = "api/department/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTypeDetails() {
      console.log("getTypeDetails called");
      this.TypeIdOptionsLoading = true;
      var selectbox1_source = "TypeId";
      var selectbox1_destination = "TypeIdOptions";
      var selectbox1_url = "api/ticket-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        DepartmentId: this.DepartmentId,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.UploadedImage1;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.SelectedImage1.src) {
            URL.revokeObjectURL(this.SelectedImage1.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.SelectedImage1 = {
            ImageName: file.name,
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.SelectedImage1 = {
            ImageName: null,
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null,
          };
          this.UploadedImage1 = {};
          break;

        default:
          break;
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.uploadImage();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        // this.toast("error", "Kindly fill the required fields", true);
      }
    },
    uploadImage() {
      console.log("uploadImage called");
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        const formData = new FormData();
        formData.append("DepartmentId", this.DepartmentId);
        formData.append("TypeId", this.TypeId);
        formData.append("Subject", this.Subject);
        formData.append("Content", this.Content);
        var UploadedImageName = this.UploadedImage1.name;
        UploadedImageName =
          UploadedImageName == (null || undefined) ? "" : UploadedImageName;
        if (UploadedImageName != "") {
          const { canvas } = this.$refs.cropper.getResult();
          if (canvas) {
            canvas.toBlob((blob) => {
              formData.append("UploadedFile1", blob, UploadedImageName);
              this.UploadFlag = true;
              this.submitForm(formData);
            }, "image/jpeg");
          } else {
            this.submitForm(formData);
          }
        } else {
          this.submitForm(formData);
        }
      } else {
        var message = "";
        if (validate1 == false) {
          message += "Kindly fill the required fields. ";
        }
        if (MemberId == 0) {
          message += "Kindly select member. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm(upload) {
      console.log("submitForm is called");

      var UploadFlag = this.UploadFlag;
      console.log("UploadFlag=" + UploadFlag);

      this.progessStart = 1;
      this.SubmitFlag = true;
      this.ResultFlag = false;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/ticket/store";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      console.log("upload=" + JSON.stringify(upload));

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SubmitFlag = false;
          thisIns.progessStart = 0;
          thisIns.UploadFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.closePrompt();
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.progessStart = 0;
          thisIns.SubmitFlag = false;
        });
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
#module-create {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>